import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Inventory } from '@models/index';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-product-quantity',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './product-quantity.component.html',
  styleUrl: './product-quantity.component.css'
})
export class ProductQuantityComponent {

  @Input() product!: Inventory;
  @Output() manipulateQuantityEvent = new EventEmitter<boolean>();
  @Input() spaceBetween = 'space-x-3'
  @Output() quantityChangedEmitter = new EventEmitter<number>();


  manipulateQuantity(increment: boolean) {
    this.manipulateQuantityEvent.emit(increment);
  }

  quantityChanged($event: number | string) {
    let quantity = $event === '' ? 0 : Number($event);
    if (isNaN(quantity) || quantity < 1) {
      quantity = 0
    }
    this.quantityChangedEmitter.emit(quantity);
    console.log(quantity);
    console.log(this.product.orderQuantity);
  }

  validateAndUpdateQuantity(value: number) {
    // Ensure value is at least 1
    const validValue = value < 1 ? 0 : value;
    this.quantityChanged(validValue);
  }

  validateInput(event: KeyboardEvent): boolean {
    const input = event.key;
    if (input === '0' && (event.target as HTMLInputElement).value === '') {
      event.preventDefault();
      return false;
    }
    return /[0-9]/.test(input) || 
      ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(input);
  }

}
