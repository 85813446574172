import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Inventory, Outlet, User } from '@models/index';
import { CartItemComponent } from "../../cart-item/cart-item.component";
import { DeliveryOptionComponent } from "../../delivery-option/delivery-option.component";
import { formatCurrency } from '../../../utils/shared/shared';
import { AppDialogComponent } from "../app-dialog/app-dialog.component";
import { TextInputFieldComponent } from "../text-input-field/text-input-field.component";
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-cart-card',
  standalone: true,
  imports: [CartItemComponent, DeliveryOptionComponent, AppDialogComponent, TextInputFieldComponent, ReactiveFormsModule  ],
  templateUrl: './cart-card.component.html',
  styleUrl: './cart-card.component.css'
})
export class CartCardComponent {


  @Input() cart: Inventory[][] = [];
  @Input() user: User | null = null;
  @Input() outlet: Outlet | undefined = undefined;
  @Input() deliveryLocation: string = '';
  @Input() deliveryFee: number = 0;
  @Input() serviceCharge: number = 0;
  @Input() totalCost = 0;
  @Input() ordersCost = 0;
  @Input() selectedDeliveryMode: number = 0;
  ordersCostString = '';
  @Input() uniqueId = '';
  @Input() cardTitle = '';
  @Input() deliveryInstructionsForm: FormControl = new FormControl();
  @Input() vendorInstructionsForm: FormControl = new FormControl();

  @Output() updateCartQuantity = new EventEmitter<{ cartItem: Inventory, increment: boolean }>();
  @Output() removeFromCart = new EventEmitter<{ cartItem: Inventory, index: number }>();
  @Output() deliveryModeChanged = new EventEmitter<number>();
  @Output() clearCart = new EventEmitter<void>();
  @Output() newOrder = new EventEmitter<void>();
  @Output() checkout = new EventEmitter<void>();
  @Output() repeatOrderEvent = new EventEmitter<void>();
  @Output() quantityChangedEmitter = new EventEmitter<{ currentCart: number, productId: number, quantity: number }>();
  @Output() changeDeliveryAddressEvent = new EventEmitter<void>();
  @Output() deleteOrderEvent = new EventEmitter<number>();
  orderInstructionsDialog: any;
  
  checkoutEvent() {
    this.checkout.emit();
  }

  toggleOrderInstructionsDialog() {
    this.orderInstructionsDialog = !this.orderInstructionsDialog;
  }

  formatCurrency = formatCurrency

  newOrderEvent() {
    this.newOrder.emit();
  }

  clearCartEvent() {
    this.clearCart.emit();
  }

  deleteOrder(index: number) {
    this.deleteOrderEvent.emit(index);
  }

  repeatOrder() {
    this.repeatOrderEvent.emit();
  }

  changeDeliveryAddress($event: void) {
    this.changeDeliveryAddressEvent.emit();
  }

  deliveryModeChangedEvent(selectedDeliveryMode: number) {
    if (selectedDeliveryMode === 1 && (!this.user?.address || !this.user?.phone || this.user?.address === '' || this.user?.phone === '')) {
      this.changeDeliveryAddressEvent.emit();
      return;
    }
    this.selectedDeliveryMode = selectedDeliveryMode;
    this.deliveryModeChanged.emit(selectedDeliveryMode);
  }

  updateCartQuantityEvent(cartItem: Inventory, increment: boolean) {
    this.updateCartQuantity.emit({ cartItem, increment });
  }

  removeFromCartEvent(cartItem: Inventory, index: number) {
    this.removeFromCart.emit({ cartItem, index });
  }

  quantityChanged(currentCart: number, productId: number, quantity: number) {
    this.quantityChangedEmitter.emit({ currentCart, productId, quantity })
  }
}
