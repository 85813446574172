
<div
  (click)="showDetailDialogToggle()" class="px-1  py-2 cursor-pointer h-[175px] bg-white rounded-xl border border-gray-200 transition-all duration-300 hover:shadow-md">
  <div class="flex flex-row justify-between items-center h-full">
    <!-- Left Content -->
    <div class="flex flex-col px-2 max-w-[70%] 2xl:px-4">
      <div class="flex flex-col justify-start space-y-1">
        <span class="text-lg font-semibold truncate lg:text-lg 2xl:text-2xl text-vendblocblue">{{ cardName }}</span>
        <span class="text-sm font-light 2xl:text-base text-vendblocblue line-clamp-2">{{product.productDescription}}</span>
        <div class="flex flex-row justify-between items-center py-1 2xl:py-2">
          <span class="font-normal text-md 2xl:text-xl text-vendblocgrey">Price</span>
          <span class="font-bold text-black text-md">{{ price}}</span>
        </div>
        <app-product-quantity [product]="product"
          (manipulateQuantityEvent)="manipulateQuantity($event)"
          (quantityChangedEmitter)="quantityChanged($event)"></app-product-quantity>
      </div>
    </div>

    <!-- Right Content -->
    <div class="flex flex-col h-full max-w-[30%] items-center justify-between">
      <div class="flex items-center justify-center h-[75%] w-full">
          <app-avatar-image [image]="product.productImage"></app-avatar-image>
      </div>
      <button (click)="addToCart(product.productId!)"
        class="px-1 py-2 text-sm text-white rounded-md lg:px-2 2xl:font-semibold lg:text-md bg-vendblocpurple">
        Add to Cart
      </button>
    </div>
  </div>
</div>
