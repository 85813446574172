import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Inventory } from '@models/index';
import { formatCurrency } from '@utils/shared/shared';
import { ProductQuantityComponent } from "../shared/product-quantity/product-quantity.component";
import { AvatarImageComponent } from "../shared/avatar-image/avatar-image.component";

@Component({
  selector: 'app-cart-item',
  standalone: true,
  imports: [ProductQuantityComponent, AvatarImageComponent],
  templateUrl: './cart-item.component.html',
  styleUrl: './cart-item.component.css'
})
export class CartItemComponent {
  @Input() cartItem!: Inventory;
  @Input() currency: string | undefined = '';
  @Input() cart: Inventory[] = [];
  @Output() updateQuantityEmit = new EventEmitter<boolean>(); 
  @Output() cartItemEmitted = new EventEmitter<Inventory>();
  @Output() quantityChangedEmitter = new EventEmitter<number>();

  removeItem() {
    this.cartItemEmitted.emit(this.cartItem);
  }

  formatCurrency = formatCurrency;

  updateQuantity(increment:boolean){
    this.updateQuantityEmit.emit(increment);
  }

  quantityChanged(quantity:number){
    this.quantityChangedEmitter.emit(quantity)
  }
}
