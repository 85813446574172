<app-dialog [(visible)]="orderInstructionsDialog" [style]="{ width: '250px' }" header="Instructions"
  styleClass="p-fluid">
  @if(this.outlet?.delivery && this.selectedDeliveryMode === 1){
  <app-text-input-field inputType="text" [label]="'Delivery Instructions'" ngDefaultControl
    placeholder="'Enter delivery instructions'" [formControl]="deliveryInstructionsForm"></app-text-input-field>
  }
  <app-text-input-field inputType="text" [label]="'Vendor Instructions'" ngDefaultControl
    placeholder="'Enter vendor instructions'" [formControl]="vendorInstructionsForm"></app-text-input-field>
  <!-- </div> -->
</app-dialog>


<div class="flex flex-col space-y-3">
  <div class="flex justify-between">
    <!-- <span class="font-bold">My Cart {{this.cartCount > 0 ? (this.cart.length > 1 ? (this.cart.length+' orders') :
      (this.cartCount+' item(s)')
      ) : '' }} </span> -->
    <span class="font-semibold">{{cardTitle}}</span>
    <span>
      @if(this.cart.length > 0){
      <i (click)="clearCartEvent()" class="text-xl text-red-500 pi pi-trash"></i>
      }

    </span>
  </div>

  @if(this.cart.length < 1) { <div class="flex justify-center items-center">
    <span class="font-semibold">There are no items in your cart</span>

</div>
}

<div class="flex max-h-[400px] flex-col overflow-auto">
  @for(orderCart of cart; track orderCart; let i = $index){
  @if(this.cart.length > 1){
  <span class="font-semibold">Order {{ i+ 1}} - {{ orderCart.length }} item(s)</span>
  @if(orderCart.length === 0){
  <div class="flex justify-end items-center px-2 cursor-pointer">
    <span (click)="repeatOrder()"
      class="relative px-2 py-1 font-semibold rounded-md group bg-vendblocpurple/5 hover:bg-vendblocpurple hover:opacity-100">
      <i class="pi pi-replay text-vendblocpurple group-hover:text-white"></i> <span
        class="opacity-100 group-hover:text-white text-vendblocpurple">Repeat Order</span>
    </span>
  </div>
  <div class="flex flex-col justify-center items-center mt-10">
    <span class="text-center pi pi-cart-arrow-down text-vendblocpurple" style="font-size: 50px;"></span>
    <span class="text-center text-vendblocpurple">No item in order {{i+1}} yet</span>
    <div class="flex justify-end items-center px-2 cursor-pointer">
      <span (click)="deleteOrder(i)"
        class="relative px-2 py-1 space-x-2 font-semibold rounded-md group bg-vendblocpurple/5 hover:bg-vendblocpurple hover:opacity-100">
        <i class="pi pi-trash text-vendblocred group-hover:text-white"></i> <span
          class="opacity-100 group-hover:text-white text-vendblocred">Delete Order {{i+1}}</span>
      </span>
    </div>
  </div>
  }
  }
  @for(orderItem of orderCart; track orderItem.id; let j = $index){
  <app-cart-item [currency]="outlet?.currency"
    (quantityChangedEmitter)="quantityChanged(i, orderItem.productId, $event)"
    (updateQuantityEmit)="updateCartQuantityEvent(orderItem, $event)" (cartItemEmitted)="removeFromCartEvent($event, i)"
    [cartItem]="orderItem"></app-cart-item>
  }
  }
</div>
@if(cart.length > 0){
<app-delivery-option (toggleDeliveryInstructionsDialogEvent)="toggleOrderInstructionsDialog()" [user]="user"
  [uniqueId]="uniqueId" [enableDelivery]="this.outlet?.delivery" [selectedDeliveryMode]="selectedDeliveryMode"
  (selectedDeliveryModeEvent)="deliveryModeChangedEvent($event)"
  (changeDeliveryAddressEvent)="changeDeliveryAddress($event)"></app-delivery-option>

<div class="p-2 rounded-lg md:p-4 bg-vendblocpurple/5">
  <div class="space-y-1">
    <span class="mb-1 font-semibold md:mb-2">Order Summary:</span>
    <div class="flex justify-between">
      <span class="font-normal">Cost of Item(s)</span>
      <span class="font-semibold text-vendblocblue">{{ formatCurrency(ordersCost, 'NGN', 0) }}</span>
    </div>
    <div class="flex justify-between">
      <span class="font-normal">Service Charge</span>
      <span class="font-semibold text-vendblocblue">{{ formatCurrency(serviceCharge, 'NGN', 0) }}</span>
    </div>
    @if(this.outlet?.delivery && this.selectedDeliveryMode === 1){
    <div class="flex justify-between">
      <span class="font-normal">Delivery Cost</span>
      <span class="font-semibold text-vendblocblue">{{ formatCurrency(deliveryFee, 'NGN', 0) }}
        ({{this.deliveryLocation}})</span>
    </div>
    }
    <hr class="border border-gray-300 border-dashed">
    <div class="flex justify-between">
      <span class="font-semibold">Total Payment</span>
      <span class="font-semibold text-vendblocpurple">{{formatCurrency(totalCost, 'NGN')}} </span>
    </div>
  </div>
</div>

}
<div class="form_div">
  @if(cart.length > 0){
  <button (click)="newOrderEvent()"
    class="flex justify-center p-3 space-x-4 rounded-md border-2 duration-300 text-vendblocpurple border-vendblocpurple create_button hover:shadow-lg"
    type="submit">
    <span>New Order</span>
  </button>
  <button (click)="checkoutEvent()"
    class="flex justify-center p-3 space-x-4 text-white duration-300 bg-vendblocpurple create_button hover:shadow-lg"
    type="submit">
    <span>Checkout ({{formatCurrency(totalCost, 'NGN')}})</span>
  </button>
  }

  <!-- <span [ngClass]="{'text-green-500' : cartCount>0}">Adesina</span> -->
</div>
</div>