<div class="flex justify-between items-center py-1 my-1 space-x-0.5 w-full border-b md:space-x-1">
  <!-- Cart Item -->
  <div class="flex flex-row space-x-3 w-[85%] md:w-[90%] py-1">
    <app-avatar-image [image]="cartItem.productImage" [size]="'large'"></app-avatar-image>
    <!-- Info -->
    <div class="flex flex-col space-y-2">
      <span class="text-base font-semibold">{{ cartItem.product }}</span>
      <div class="flex flex-row justify-between space-x-4">
        <app-product-quantity spaceBetween="space-x-1" [product]="cartItem"
          (manipulateQuantityEvent)="updateQuantity($event)"
          (quantityChangedEmitter)="quantityChanged($event)"></app-product-quantity>
          <span class="font-bold text-vendblocblue">{{
            formatCurrency(cartItem.salesPrice, 'NGN', 0)
            }}</span>
      </div>
    </div>
  </div>
  <!-- Remove from Cart -->
  <div class="flex flex-row justify-center items-center w-[15%] md:w-[10%] space-x-2">
    <span (click)="removeItem()"
      class="p-1.5 font-bold rounded-full cursor-pointer bg-vendblocpurple/10 text-vendblocpurple hover:text-vendblocpurple hover:bg-vendblocred/20 pi pi-times">
    </span>
  </div>
</div>