<div class="flex justify-start items-center space-x-3 md:{{spaceBetween}}">
    <span (click)="manipulateQuantity(false)" class="flex justify-center items-center w-6 h-6 text-lg bg-gray-300 rounded-full cursor-pointer text-vendblocpurple text-md pi pi-minus grow-0 shrink-0" style="font-size: 10px;">
    </span>
    <span>
        <input class="p-1 px-0.5 w-7 text-sm text-center align-middle rounded-sm text-vendblocblue lg:rounded-md"
            type="text"
            min="1"
            [ngModel]="product.orderQuantity!"
            (ngModelChange)="validateAndUpdateQuantity($event)"
            (keypress)="validateInput($event)"
            name="quantity" />
    </span>
    <span (click)="manipulateQuantity(true)" class="flex justify-center items-center w-6 h-6 text-lg font-thin text-white rounded-full cursor-pointer text-md pi pi-plus grow-0 shrink-0 bg-vendblocpurple" style="font-size: 10px;">
    </span>
  </div>