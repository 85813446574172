import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Inventory } from '@models/index';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProductQuantityComponent } from "../product-quantity/product-quantity.component";
import { AvatarImageComponent, AppDialogComponent } from '../index';
import { formatCurrency } from '@utils/shared/shared';

@Component({
  selector: 'app-order-product-card',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, ProductQuantityComponent, AvatarImageComponent, AppDialogComponent],
  templateUrl: './order-product-card.component.html',
  styleUrl: './order-product-card.component.css'
})
export class OrderProductCardComponent {

  showDetailDialog: boolean = false;

  @Input() cardName: string = '';
  @Input() iconBackgroundColor: string = '';
  @Input() cardCount: string = '0';
  @Input() icon: string = '';
  @Input() product! : Inventory;
  @Output() addToCartEvent = new EventEmitter<number>();
  @Output() manageQuantity = new EventEmitter<{cartItem:Inventory ,increment:boolean}>(); 
  @Output() quantityChangedEmitter = new EventEmitter<{productId:number ,quantity:number}>(); 


  get price(): string {
    if (this.product.orderQuantity < 1) {
      return formatCurrency(this.product.salesPrice, 'NGN', 0);
    }
    return formatCurrency(this.product.salesPrice * this.product.orderQuantity, 'NGN', 0);
  }

  manipulateQuantity(increment: boolean ) {
    this.manageQuantity.emit({cartItem: this.product,increment:increment});
  }

  quantityChanged(quantity: number) {
    const productId = this.product.productId
    this.quantityChangedEmitter.emit({productId,quantity})
  }

  addToCart(id:number){
     this.addToCartEvent.emit(id);
  }

  showDetailDialogToggle(){
    this.showDetailDialog = !this.showDetailDialog
  }
}
