<div class="p-2 space-y-1 rounded-lg md:p-4 bg-vendblocpurple/5 md:space-y-2 2xl:space-y-4">

  <div>
    <!-- "Delivery Mode: "{{selectedDeliveryMode}} <br> -->
    @if(enableDelivery){
    <span class="mb-1 font-semibold md:mb-2">Choose delivery options</span>
    }
  </div>
  <div class="flex justify-between">
    <div class="flex items-center space-x-1">
      <input type="radio" [(ngModel)]="selectedDeliveryMode" (change)="deliveryModeChanged()" [value]=0
        class="custom-radio" [attr.name]="'delivery_'+uniqueId" id="pickup" />
      <label [class.text-vendblocpurple]="selectedDeliveryMode === 0" for="pickup">Pick Up</label>
    </div>
    @if(enableDelivery){
    <div class="flex items-center space-x-1">
      <input type="radio" [(ngModel)]="selectedDeliveryMode" (change)="deliveryModeChanged()" [value]=1
        class="custom-radio" [attr.name]="'delivery_'+uniqueId" id="deliver" />
      <label for="deliver">Delivery</label>
    </div>}
  </div>

  <div class="flex-col mt-2 space-y-1">
    @if(selectedDeliveryMode === 1){
    <div class="flex justify-between">
      <span class="font-normal">{{user?.address}}</span>
      <span class="font-semibold cursor-pointer text-vendblocpurple" (click)="changeDeliveryAddress()">Change</span>
    </div>
    <div class="flex justify-between">
      <span class="font-normal">Delivery Instruction</span>
      <span class="font-semibold cursor-pointer text-vendblocpurple" (click)="toggleDeliveryInstructionsDialog()">Add Note</span>
    </div>
  }
    <div class="flex justify-between font-semibold">
      <span class="font-normal">Vendor Instruction</span>
      <span class="font-semibold cursor-pointer text-vendblocpurple" (click)="toggleDeliveryInstructionsDialog()">Add Note</span>
    </div>
  </div>
</div>